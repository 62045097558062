import React from 'react'
// interfaces
import { IImage } from '../../interfaces/image'
// styles
import styles from './background-image.module.scss'

interface IProps {
  image: IImage
}

const BackgroundImage: React.FC<IProps> = ({ image }: IProps) => (
  <img
    className={styles.backgroundImage}
    src={`${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w1700`}
    srcSet={`
      ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=1700 1700w,
      ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=1280 1280w,
      ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=768 768w,
      ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=375 375w,
    `}
    sizes="100vw"
    alt=""
  />
)

export default BackgroundImage
