import React, { useContext, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { useForm } from 'react-hook-form'
import { setCookie, getCookie } from '../../services/cookies'
// context
import { GlobalContext } from '../layout'
// components
import SubmitButton from '../submit-button'
import Modal from '../modal'
import ModalHeader from '../modal/modal-header'
import ModalBody from '../modal/modal-body'
// queries
import { CART, ADD_ITEM_TO_CART, ADD_COUPON } from '../../queries/cart'
// services
import { formatGraphQLError } from '../../services/format'
// styles
import styles from './add-to-cart.module.scss'
import fadeTransition from '../../styles/fade.module.scss'
import { cookieData } from '../../constants/variables'
import { getToken } from '../../services/auth'
import { OfferCodes } from '../../constants'
import { navigate } from '@reach/router'

interface IProps {
  plans: any
  isFreeTrial?: boolean
  showPlanLabel?: boolean
  productType?: string
  showButtonOnly?: boolean
  showDeliveryText?: boolean
  displaySinglePlanLabel?: boolean
  daysOffer?: boolean
}

const AddToCart: React.FC<IProps> = ({
  plans,
  isFreeTrial = false,
  showPlanLabel = false,
  productType = '',
  showDeliveryText = true,
  showButtonOnly = false,
  displaySinglePlanLabel = false,
  daysOffer = false,
}: IProps) => {
  const { setShowCart, setShowUpsell } = useContext(GlobalContext)
  const [showAddCoupon, setShowAddCoupon] = useState(false)
  const buttonLabel = isFreeTrial ? 'Claim free trial' : 'Add to basket'
  const chosenCurrency = getCookie('currency')

  const [getCart, { data: cartData }] = useLazyQuery(CART, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      setShowCart(true)

      const oneItemInCart = cartData && cartData.cart.content.itemCount === 1

      const isVitaminDinBasket = () => {
        const includesVitD =
          cartData &&
          cartData.cart.content.items.filter(
            (item: any) =>
              item.name === 'Vitamin D - One off' ||
              item.name === 'Vitamin D - Monthly subscription'
          )

        return includesVitD.length > 0
      }

      oneItemInCart && !isVitaminDinBasket() && setShowUpsell(true)
    },
  })

  const [
    addItemToCart,
    { loading: addItemToCartLoading, error: addItemToCartError },
  ] = useMutation(ADD_ITEM_TO_CART, {
    update: (cache, addItemResponse) => {
      const cookieExpiry = 30 * 24 * 60 * 60 // 30 days
      setCookie(
        'cart-id',
        addItemResponse.data.cart_addItem.cartId,
        cookieExpiry
      )

      getCart({
        variables: {
          id: getCookie('cart-id'),
        },
      })
    },
    onError: error => {
      setModalError(error)
    },
  })

  const [modalError, setModalError] = useState(false)

  const [
    addCoupon,
    { data: addCouponData, loading: addCouponLoading, error: addCouponError },
  ] = useMutation(ADD_COUPON, {
    update: (cache, addCouponData) => {
      const data: any = cache.readQuery({
        query: CART,
        variables: {
          id: getCookie('cart-id'),
        },
      })
      data.cart.content = addCouponData.data.cart_addCoupon
      cache.writeQuery({ query: CART, data })
    },
    onCompleted: addCouponData => {
      addCouponData && setShowAddCoupon(false)

      getCart({
        variables: {
          id: getCookie('cart-id'),
        },
      })
    },
  })

  const defaultPlan = plans && plans.find((plan: any) => plan.default)

  const { register, handleSubmit } = useForm({
    defaultValues: {
      sku: defaultPlan ? defaultPlan.sku : plans[0].sku,
    },
  })

  const { register: registerOneOff } = useForm({
    defaultValues: {
      sku: 'cbd-gummies-tin-one-time',
    },
  })

  const handleAddItemToCart = (sku: string) => {
    const cartId = getCookie('cart-id')
    const essentialOneCoupon =
      cookieData[getCookie('essentialOnePartnership')]?.couponCode
    const partnershipCoupon =
      cookieData[getCookie('personalisedPlusPartnership')]?.couponCode

    if (
      getToken() &&
      essentialOneCoupon &&
      productType === OfferCodes.EssentialOne
    ) {
      addCoupon({
        variables: {
          cartId,
          couponCode: essentialOneCoupon,
        },
      })
      return
    }

    if (
      getToken() &&
      partnershipCoupon &&
      productType === OfferCodes.PersonalisedPlus
    ) {
      addCoupon({
        variables: {
          cartId,
          couponCode: partnershipCoupon,
        },
      })
      return
    }

    addItemToCart({
      variables: {
        cartId,
        sku,
        quantity: 1,
      },
    })
  }

  const handleOptionSubmit = (formData: any) => {
    const sku = formData['sku']
    navigate(
      `${process.env.GATSBY_VITL_HOST_NAME}/?addPlanToCart=${
        plans.length === 1 ? 'cbd-gummies-tin-one-time' : sku
      }&currency=${getCookie('currency')}`
    )
    // handleAddItemToCart(sku);
  }

  const price = (plan: any) =>
    plan.offerPriceLabel ? plan.offerPriceLabel : plan.rrpPriceLabel

  return (
    <div
      className={
        showButtonOnly ? styles.containerWithoutMargin : styles.container
      }
    >
      <div className={styles.content}>
        {daysOffer && (
          <p className={styles.daysOffer}>
            BUY TODAY & SAVE |{' '}
            <span>
              <del>{plans[0].rrpPriceLabel}</del>
            </span>{' '}
            {plans[0].offerPriceLabel}
          </p>
        )}

        {/*{plans.length === 1 && !displaySinglePlanLabel && (*/}
        {/*  <div>*/}
        {/*    {!showButtonOnly && (*/}
        {/*      <p className={styles.priceWrapper}>*/}
        {/*        {plans[0].offerPriceLabel && (*/}
        {/*          <span className={styles.oldPrice}>*/}
        {/*            <del>{plans[0].rrpPriceLabel}</del>*/}
        {/*          </span>*/}
        {/*        )}*/}

        {/*        <span className={styles.price}>*/}
        {/*          {plans[0].offerPriceLabel*/}
        {/*            ? plans[0].offerPriceLabel*/}
        {/*            : plans[0].rrpPriceLabel}*/}
        {/*        </span>*/}
        {/*      </p>*/}
        {/*    )}*/}
        {/*    <SubmitButton*/}
        {/*      buttonLabel={buttonLabel}*/}
        {/*      loading={addItemToCartLoading}*/}
        {/*      onClick={() => handleAddItemToCart(plans[0].sku)}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        {(plans.length >= 1 || displaySinglePlanLabel) && (
          <form onSubmit={handleSubmit(handleOptionSubmit)}>
            <div className={styles.radioGroup}>
              {plans.map((plan: any) => (
                <div key={plan.sku} className={styles.radioWrapper}>
                  <label className={styles.radioContainer}>
                    <input
                      type="radio"
                      name="sku"
                      value={plan.sku}
                      ref={
                        plans.length === 1
                          ? registerOneOff({ required: true })
                          : register({ required: true })
                      }
                    />
                    <span className={styles.checkmark}></span>

                    <div className={styles.radioLabel}>
                      <span>{plan.planLabel}</span>
                      <span>{price(plan)}</span>
                    </div>
                  </label>
                </div>
              ))}
            </div>

            <SubmitButton
              loading={addItemToCartLoading}
              buttonLabel={buttonLabel}
              onClick={handleSubmit(handleOptionSubmit)}
            />
          </form>
        )}

        {chosenCurrency === 'GBP' && !showButtonOnly && showDeliveryText && (
          <div className={styles.delivery}>
            {showPlanLabel && <p>{plans[0].planLabel}</p>}
            {!isFreeTrial ? (
              <p className={styles.small}>Free UK delivery over £14</p>
            ) : (
              <p className={styles.small}>
                £3.95 P&amp;P - free delivery after 1st order
                <br />
                Pause or cancel anytime
              </p>
            )}
          </div>
        )}

        <CSSTransition
          in={modalError}
          timeout={300}
          classNames={{ ...fadeTransition }}
          unmountOnExit
        >
          <Modal handleClose={() => setModalError('')}>
            <ModalHeader>
              <h3>Unavailable in your region</h3>
            </ModalHeader>
            <ModalBody>
              <p>{formatGraphQLError(modalError)}</p>
            </ModalBody>
          </Modal>
        </CSSTransition>
      </div>
    </div>
  )
}

export default AddToCart
