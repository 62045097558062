import React, { useState } from 'react'
// styles
import styles from './accordion.module.scss'

interface IProps {
  items: Array<any>
  isTransparent?: boolean
  isHtmlContent?: boolean
  openIfSingleItem?: boolean
  withEffects?: boolean
}

const Accordion: React.FC<IProps> = ({
  withEffects = false,
  items,
  isTransparent = false,
  openIfSingleItem = false,
  isHtmlContent = false,
}) => {
  const active = items.length === 1 && openIfSingleItem ? 0 : -1
  const [activeItemIndex, setActiveItemIndex] = useState(active)

  const isTransparentClass = isTransparent
    ? styles.listTransparent
    : styles.list
  const withEffectsClass = withEffects ? styles.listWithEffects : ''

  return (
    <ul className={`${isTransparentClass} ${withEffectsClass}`}>
      {items.map((item: any, i: number) => (
        <li
          className={i === activeItemIndex ? styles.itemActive : styles.item}
          key={i}
        >
          <div
            className={styles.itemHeader}
            onClick={() => setActiveItemIndex(activeItemIndex !== i ? i : -1)}
          >
            {item.question}
          </div>
          {isHtmlContent ? (
            <div
              className={styles.itemBody}
              dangerouslySetInnerHTML={{ __html: item.answer }}
            />
          ) : (
            <div className={styles.itemBody}>{item.answer}</div>
          )}
        </li>
      ))}
    </ul>
  )
}

export default Accordion
