import React from 'react'

interface IProps {
  filename: string
  title?: string
  mobileSize: number
  tabletSize?: number
  desktopSize: number
  lazyload?: boolean
}

const ResponsiveImage: React.FC<IProps> = ({
  filename,
  title = '',
  mobileSize,
  tabletSize,
  desktopSize,
  lazyload = true,
}) => {
  if (!tabletSize) {
    tabletSize = desktopSize
  }

  if (lazyload) {
    return (
      <img
        className="lazyload"
        data-src={`${process.env.GATSBY_MEDIA_URL}${filename}?w=${mobileSize}`}
        data-srcset={`
          ${process.env.GATSBY_MEDIA_URL}${filename}?w=${mobileSize} ${mobileSize}w,
          ${process.env.GATSBY_MEDIA_URL}${filename}?w=${tabletSize} ${tabletSize}w,
          ${process.env.GATSBY_MEDIA_URL}${filename}?w=${desktopSize} ${desktopSize}w
        `}
        data-sizes={`
          (max-width: 768px) ${mobileSize}px,
          (max-width: 1280px) ${tabletSize}px,
          ${desktopSize}px
        `}
        alt={title}
      />
    )
  }

  return (
    <img
      src={`${process.env.GATSBY_MEDIA_URL}${filename}?w=${mobileSize}`}
      srcSet={`
        ${process.env.GATSBY_MEDIA_URL}${filename}?w=${mobileSize} ${mobileSize}w,
        ${process.env.GATSBY_MEDIA_URL}${filename}?w=${tabletSize} ${tabletSize}w,
        ${process.env.GATSBY_MEDIA_URL}${filename}?w=${desktopSize} ${desktopSize}w
      `}
      sizes={`
        (max-width: 768px) ${mobileSize}px,
        (max-width: 1280px) ${tabletSize}px,
        ${desktopSize}px
      `}
      alt={title}
    />
  )
}

export default ResponsiveImage
