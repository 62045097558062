import React from 'react'
import { Link } from 'gatsby'
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// components
import ResponsiveResolutionImage from '../responsive-resolution-image'
// styles
import styles from './recommended-products.module.scss'

interface IProps {
  title: string
  products: any
}

const RecommendedProducts: React.FC<IProps> = ({ title, products }: IProps) => {
  SwiperCore.use([Pagination])

  return null

  //   return(
  //     <section>
  //       <div className={styles.container}>
  //         <div className={styles.row}>
  //           <div className={styles.content}>
  //             <h2>{title}</h2>
  //             <hr />
  //           </div>
  //         </div>

  //         <div className={styles.productsWrapper}>
  //           <Swiper
  //             pagination={{ clickable: true }}
  //             spaceBetween={20}
  //             slidesPerView={1}
  //             breakpoints={
  //               {
  //                 768: {
  //                   width: 640,
  //                 },
  //                 1280: {
  //                   width: 1200,
  //                   slidesPerView: 3,
  //                 }
  //               }
  //             }
  //           >

  //             {products.map((product: any, index: number) =>
  //               <SwiperSlide key={index} className={styles.product}>
  //                 <Link to={`/product/${product.id}`} className={styles.image}>
  //                   <ResponsiveResolutionImage
  //                     filename={product.image_filename_disk}
  //                     title={product.title}
  //                     width={360}
  //                   />
  //                 </Link>
  //                 <h4>{product.title}</h4>
  //                 <hr />
  //                 <p className={styles.price}>From {product.price}</p>
  //                 <Link
  //                   to={`/product/${product.id}`}
  //                   className={styles.buttonInverted}
  //                 >
  //                   Find out more
  //                 </Link>
  //               </SwiperSlide>
  //             )}

  //           </Swiper>
  //         </div>
  //       </div>
  //     </section>
  //   )
}

export default RecommendedProducts
