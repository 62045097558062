import gql from 'graphql-tag'
import { pillFragment } from './user'

const planFragment = gql`
  fragment plan on ProductPlan {
    sku
    default
    planLabel
    rrpPrice
    rrpPriceLabel
    offerPrice
    offerPriceLabel
    type
    currency
  }
`

export const GET_PRODUCT = gql`
  query get_product(
    $slug: ID
    $id: ID
    $freeTrial: Boolean
    $partnership: String
  ) {
    product_getProduct(
      slug: $slug
      id: $id
      freeTrial: $freeTrial
      partnership: $partnership
    ) {
      id
      benefits
      fromAmount
      grouping
      iconUrl
      imageUrl
      label
      longDescription
      name
      pageDescription
      pageTitle
      shortDescription
      pills {
        id
        description
        iconUrl
        ingredients {
          name
          quantity
          rda
          type
        }
        ingredientsList
        label
        longDescription
        pillFormLabel
        shortDescription
      }
      plans {
        ...plan
      }
      webBlockFourTitle
      webBlockOneDescription
      webBlockOneTitle
      webBlockThreeDescription
      webBlockThreeDirectionsDescription
      webBlockThreeDirectionsTitle
      webBlockThreeImage
      webBlockThreeMaximumEfficacyDescription
      webBlockThreeMaximumEfficacyTitle
      webBlockThreeNoNastiesDescription
      webBlockThreeNoNastiesTitle
      webBlockThreeTitle
      webBlockTwoDescription
      webBlockTwoTitle
    }
  }
  ${planFragment}
`

export const PRODUCT_PLANS = gql`
  query product_plans(
    $productId: ID!
    $currencyCacheKey: String
  ) {
    product_plans(
      productId: $productId
      currencyCacheKey: $currencyCacheKey
    ) {
      ...plan
    }
  }
  ${planFragment}
`;
