import React, { useState } from 'react'
// styles
import styles from './product-options.module.scss'
// components
import ResponsiveResolutionImage from '../responsive-resolution-image'

interface IProps {
  productOptions: any
  setSelectedOption: any
}

const ProductOptions: React.FC<IProps> = ({
  productOptions,
  setSelectedOption,
}: IProps) => {
  const [activeOption, setActiveOption] = useState<number>(0)

  const handleSelectOption = (optionName: any, index: number) => {
    setActiveOption(index)
    setSelectedOption(optionName)
  }

  return (
    <div className={styles.options}>
      <ul className={styles.optionsList}>
        {productOptions.map((item: any, index: number) => (
          <li
            key={index}
            onClick={() => handleSelectOption(item.option_name, index)}
            className={
              activeOption === index
                ? styles.optionItemActive
                : styles.optionItem
            }
          >
            <ResponsiveResolutionImage
              width={60}
              filename={item.cart_filename_disk}
            />
            <div className={styles.optionText}>
              <label>{item.option_name}</label>
              {/*<span>{item.option_price}</span>*/}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ProductOptions
