import React from 'react'
// interfaces
import { IImage } from '../../interfaces/image'
// components
import BackgroundImage from '../background-image'
import AddToCart from '../add-to-cart/add-to-cart'
// styles
import styles from './hero-fullscreen.module.scss'
import ProductOptions from '../product-options'

interface IProps {
  title: string
  description?: string
  heroImageAlt?: string
  direction?: string
  image?: IImage
  backgroundImage?: IImage
  backgroundColor?: string
  loadingPlans?: boolean
  plans?: any
  productOptions?: any
  setSelectedOption: any
}

const HeroFullscreen: React.FC<IProps> = ({
  title,
  description,
  heroImageAlt = '',
  direction = '',
  image,
  backgroundColor = '',
  backgroundImage,
  loadingPlans,
  plans,
  productOptions,
  setSelectedOption,
}: IProps) => {
  return (
    <section className={styles['hero' + backgroundColor + direction]}>
      <div className={styles.container}>
        <div className={styles['row' + direction]}>
          {backgroundImage && <BackgroundImage image={backgroundImage} />}
          <div className={styles.textContainer}>
            <div className={styles.text}>
              {title && <h1 className={styles.mainTitle}>{title}</h1>}

              {title && (
                <hr
                  className={backgroundColor === '' ? styles.hrDark : styles.hr}
                />
              )}

              {image && (
                <img
                  className={styles.imageShowMobile}
                  src={`${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=700`}
                  srcSet={`
              ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=700 700w,
              ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=1400 1400w,
            `}
                  sizes="100vw"
                  alt={heroImageAlt}
                />
              )}

              {description && (
                <p className={styles.subheading}>{description}</p>
              )}

              {productOptions && (
                <ProductOptions
                  productOptions={productOptions}
                  setSelectedOption={setSelectedOption}
                />
              )}

              {!loadingPlans &&
                (plans && plans.length ? (
                  <AddToCart plans={plans} />
                ) : (
                  <h4 className={styles.outOfStock}>Out of stock</h4>
                ))}
            </div>
          </div>
          {image && (
            <img
              className={styles.image}
              src={`${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=700`}
              srcSet={`
              ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=700 700w,
              ${process.env.GATSBY_MEDIA_URL}${image.filename_disk}?w=1400 1400w,
            `}
              sizes="100vw"
              alt={heroImageAlt}
            />
          )}
        </div>
      </div>
    </section>
  )
}

export default HeroFullscreen
